
  export const style={
    inpuStyle:{
      colorInput:"#00468F"
    },
    url:{
      urlClient:"https://cipa.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/logo_client_cipa.png"
    },
    CurrencyName:{
      name:"Cipacoin/Cipacoins"
    },
    teamName:{
      name:"Cipa"
    },
    hasToken: false,
    hostHeader: "cipa"
  }
